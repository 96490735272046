
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ListaEquipe from "../components/lista-equipe"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query QuemSomosQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
				title_seo
        description_seo
        box1 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
					title
          description
					subtitle
          subdescription
					fb_link
					fb_icon
					insta_link
					insta_icon
        }
				box2 {
          imageBackground {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
              sizes {
                src
              }
            }
          }
					title
					lista_valores_1
					lista_valores_2
					lista_valores_3
					lista_valores_4
        }
				atuacao1 {
					icone {
						publicURL
						childImageSharp {
							fixed(width: 125, height: 125) {
								...GatsbyImageSharpFixed
							}
						}
					}
					title
					description
				}
				atuacao2 {
					icone {
						publicURL
						childImageSharp {
							fixed(width: 125, height: 125) {
								...GatsbyImageSharpFixed
							}
						}
					}
					title
					description
				}
				atuacao3 {
					icone {
						publicURL
						childImageSharp {
							fixed(width: 125, height: 125) {
								...GatsbyImageSharpFixed
							}
						}
					}
					title
					description
				}
      }
    }
		site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const QuemSomosPage = ({ data }) => {
	const { markdownRemark, site } = data
	const { frontmatter } = markdownRemark
	const { siteUrl } = site.siteMetadata

	return (
		<Layout>
			<SEO 
        title={frontmatter.title_seo}
        description={frontmatter.description_seo} 
      />
			<section className="arquitetura top-espaco">
				<div className="container-fluid hidden-xs">
					<Img 
						className={`col-xs-12 col-sm-offset-6 col-sm-6 col-md-offset-6 col-md-6 col-lg-offset-6 col-lg-6 quem-somos`}
            fluid={frontmatter.box2.imageBackground.childImageSharp.fluid} 
					/>
					<Img 
						className={`col-xs-12 col-sm-6 col-md-6 col-lg-6 cultura`}
            fluid={frontmatter.box1.imageBackground.childImageSharp.fluid} 
					/>
        </div>
				<div className="container texto-parallax">
					<div className="row">
						<Img 
							className={`col-xs-12 visible-xs cultura`}
							fluid={frontmatter.box1.imageBackground.childImageSharp.fluid} 
						/>
						<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 texto-qs">
							<h3 className="text-center">{frontmatter.box1.title}</h3>
							<p>{frontmatter.box1.description}</p>
							<p className="social-empresa">
								<a target="_blank" rel="noreferrer" href={frontmatter.box1.fb_link}>
									<i className={frontmatter.box1.fb_icon}></i>
								</a>
								<a target="_blank" rel="noreferrer" href={frontmatter.box1.insta_link}>
									<i className={frontmatter.box1.insta_icon}></i>
								</a>
							</p>
							<h3>{frontmatter.box1.subtitle}</h3>
							<p>{frontmatter.box1.subdescription}</p>
						</div>

						<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6"></div>
						<Img 
							className={`col-xs-12 visible-xs cultura`}
							fluid={frontmatter.box2.imageBackground.childImageSharp.fluid} 
						/>
						<div className="col-xs-12 col-sm-offset-6 col-sm-6 col-md-offset-6 col-md-6 col-lg-offset-6 col-lg-6 valores">
							<h3>{frontmatter.box2.title}</h3>
							<div className="col-xs-12 col-sm-12">
								<p className="valor">{frontmatter.box2.lista_valores_1}</p>
								<p className="valor">{frontmatter.box2.lista_valores_2}</p>
								<p className="valor">{frontmatter.box2.lista_valores_3}</p>
								<p className="valor">{frontmatter.box2.lista_valores_4}</p>                        
							</div>                        
						</div>
					</div>
				</div>

				<div className="container">
					<div className="row atuacao">
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<img className="img-responsive" src={siteUrl + frontmatter.atuacao1.icone.publicURL} alt="icone estudos urbanos" />
							<h4>{frontmatter.atuacao1.title}</h4>
							<p>{frontmatter.atuacao1.description}</p>
						</div>
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<img className="img-responsive" src={siteUrl + frontmatter.atuacao2.icone.publicURL} alt="icone projetos" />
							<h4>{frontmatter.atuacao2.title}</h4>
							<p>{frontmatter.atuacao2.description}</p>
						</div>
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<img className="img-responsive" src={siteUrl + frontmatter.atuacao3.icone.publicURL} alt="icone execução de obras" />
							<h4>{frontmatter.atuacao3.title}</h4>
							<p>{frontmatter.atuacao3.description}</p>
						</div>
					</div>
        </div>

				<div className="container equipe">
					<div className="row">
						<h3 className="equipe-titulo">EQUIPE</h3>
						<hr className="separador-equipe" />
					</div>
					<div className="row">
						<div className="col-xs-12">
							<ListaEquipe />
						</div>
					</div>
        </div>

			</section>

		</Layout>
	)
}

export default QuemSomosPage