import React from "react"
import { StaticQuery, graphql } from "gatsby"

import ColaboradoresCard from "./colaborador-card"

const EquipeMaker = ({ data }) => (
    <>
      {data}
    </>
)

export default function ListaEquipe() {
  return (
    <StaticQuery 
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___ordem] }
            filter: { frontmatter: { template: { eq: "colaborador" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  title
                  nome
                  nomeCompleto
                  cargo
                  ordem
                  description
                  featuredImage {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 255, maxHeight: 300, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      }

      render={ data => {
          const colaboradores = data.allMarkdownRemark.edges
            .filter(edge => !!edge.node.frontmatter.ordem)
            .map(edge => <ColaboradoresCard key={edge.node.id} data={edge.node} />)
            
          return <EquipeMaker data={colaboradores} />
        } 
      }
    />
  )
}