import React from "react"
import Img from "gatsby-image"

const ColaboradoresCard = ({ data }) => (
  <div className="col-xs-12 col-sm-3 mb-box-equipe">
		<div className="box-equipe">
      <button
        data-fancybox="equipe_s12"
        data-src={"#" + data.frontmatter.nome}
      >
        <img className="img-responsive" src={data.frontmatter.featuredImage.publicURL} alt={"Equipe S12 - " + data.frontmatter.nomeCompleto} />
        <div className="hover">
          <img className="img-responsive" src={data.frontmatter.featuredImage.publicURL} alt={"Equipe S12 - " + data.frontmatter.nomeCompleto} />
          <div className="texto-hover">
            <div className="hover-nome">
              <h4 className="nome-membro">
                {data.frontmatter.nome}<br />
                <small>{data.frontmatter.cargo}</small>
              </h4>
            </div>
          </div>
        </div>
      </button>
      <div
        id={data.frontmatter.nome}
        className="lightbox-equipe"
        style={{display: 'none'}} 
      >
        <div className="row row-no-margin">
          <Img 
            className="col-xs-12 col-sm-4 lightbox-img"
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid} 
          />
          <div className="col-xs-12 col-sm-8 ">
            <h2>{data.frontmatter.nomeCompleto}</h2>
            <p>{data.frontmatter.description}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ColaboradoresCard